import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import { Video, video_data } from "../components/VideoBox"
class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoNo: false,
      compact: false,
    }
  }

  openModal = videoNo => {
    this.setState({ videoNo })
  }
  closeModal = index => {
    this.setState({ videoNo: false })
  }
  toggle=(action)=> {
    this.setState({
      compact: !!action,
    })
  }

  render() {
    return (
      <Layout>
        <PageHeader title="Videos" subtitle="Videos from VoerEir" />
        <section className="section-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mobile-margin-top">
                <div className="col-limit-3">
                  <div className="heading-top"></div>
                  <h2 className="heading-2 mb-5">Touchstone Videos</h2>
                </div>
              </div>
            </div>
            {/*<div className="col-lg-12 button-box mb-5">
                <span className="small">change layout:</span>
                <button
                  className="ml-2 svg-button"
                  onClick={e => this.toggle(false)}
                >
                  <ListIcon
                    className={`${this.state.compact ? "opacity" : ""}`}
                  />
                </button>
                <button className="ml-2 svg-button" onClick={e => this.toggle(true)}>
                  <TileIcon
                    className={`${this.state.compact ? "" : "opacity"}`}
                  />
                </button>
              </div>*/}
              <div className={`main-media ${this.state.compact ? "compact" : ""}`}>
              {video_data.map((o, i) => (
                <Video
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  videoNo={this.state.videoNo}
                  index={i}
                  key={i}
                  {...o}
                />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SecondPage
