import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { getFileName } from "../utils"
import ModalVideo from "react-modal-video"

export const Video = ({
  imgSrc = "media_placeholder.jpg",
  heading = "-",
  channel = "vimeo",
  videoId = "",
  videoNo,
  index,
  closeModal,
  openModal,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1920) {
              originalImg
            }
          }
        }
      }
    }
  `)

  data.allImageSharp.edges.forEach(element => {
    if (getFileName(element.node.fluid.originalImg) === imgSrc) {
      imgSrc = element.node.fluid.originalImg
    }
  })
  return (
    <>
      <ModalVideo
        channel={channel}
        isOpen={videoNo === index ? true : false}
        videoId={videoId}
        onClose={e => closeModal(index)}
      />
    
      <div
        onClick={e => openModal(index)}
        className="media-box"
        style={{ cursor: "pointer" }}
      >
        <div
          className="main-img"
          style={{ backgroundImage: `url(${imgSrc})` }}
        ></div>
        <div className="content text-center-mobile">
          <h4>{heading}</h4>
        </div>
      </div>
    </>
  )
}

export default Video

export const video_data = [
  {
    heading: "VoerEir’s strategy for a successful NFV transformation",
    imgSrc: "video_first.png",
    videoId: "365212170",
    channel: "vimeo",
  },
  {
    heading: "Part 1: A quick introduction to Touchstone.",
    imgSrc: "media_second.png",
    videoId: "365212212",
    channel: "vimeo",
  },
  {
    heading: "Part 2: A guided tour in the GUI of Touchstone.",
    imgSrc: "media_thirdd.png",
    videoId: "365214156",
    channel: "vimeo",
  },
]
